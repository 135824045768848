import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link, animateScroll as scroll } from 'react-scroll';
import hero from "./../components/images/hero.png";
import herom from "./../components/images/hero-mobile.png";
import playstore from "./../components/images/playstore.png";
import appstore from "./../components/images/appstore.png";

const contentVariants = {
    initial: {
      translateY: 0, // Set the initial position at the resting point
    },
    animate: {
      translateY: [-20, 0, -20, 0], // Animate between -20 and 0 (up and down)
      transition: {
        yoyo: Infinity, // Continuously repeats the animation
        duration: 6, // Animation duration in seconds
      },
    },
  };
  
function Welcome() {
    return (
        <section
        className='welcome'
        data-aos="fade-down" 
        name="home"
        id="home"
        >
            <div class="row">
                <div className='row-left'>
                  <div className='hero-left'>
                        <h3 className='text-center'>Cultivating Female Excellence: Your Mentorship Hub</h3>
                        <p className='mt-3 text-center'>
                        Connect, Grow, and Unleash Your Full Potential with the Support of a Thriving Community of Mentors and Mentees.
                        </p>  
                        <div className='download-icon-wrapper mt-3'>
                        <a href="https://play.google.com/store/apps/details?id=com.herpath&pli=1"><img src={playstore} /></a>
                        <a href=""><img src={appstore} /></a>
                        </div>
                    </div>        
                </div>
                <div className='row-right'>
                <motion.div
                className=''
                animate='animate'
                variants={contentVariants}
              >
                <img src={hero} className='hero-image' />
                <img src={herom} className='hero-image-mobile' alt="About" />

              </motion.div>
                </div>
            </div>
           
        </section>
  );
}
export default Welcome;
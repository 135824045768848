import React, { useState } from 'react';
import about from "./images/about.png";

function About() {

  return (
    <section
      name="about"
      id="about"
      className='about mt-5 mb-5'
    >
      <div className="row row-reverse">
        <div className="row-left">
          <img src={about} className='about-image' alt="About" />
        </div>
        <div className="row-right about-row-right">
          <div className="switch-tab-container mt-3">
            <div className='tabs mb-3'>
              <span className="active-tab">
                About
              </span>
              <span className="tab2">
                Get to know HerPath
              </span>
            </div>
           
              <div  className="active-tab-content">
                <h1 className='mb-2 text-center'>HerPath: Shaping Futures Through Mentorship</h1>
                <p className='mb-3 text-center'>
                    Welcome to HerPath, a transformative mentorship platform designed by and for women. We believe that mentorship is the key to unlocking the full potential of every woman, and our platform is where this journey begins.                
                    </p>
                    <p className='mb-3 text-center'>
                    At HerPath, we've created a thriving community of women who are passionate about learning, inspiring, and succeeding together. Our platform serves as the bridge connecting women seeking mentorship with mentors eager to share their experiences and knowledge.
                    </p>
                    <p className='mb-3 text-center'>
                    In this dynamic space, you have the power to explore mentorship opportunities that resonate with your aspirations. You can easily schedule valuable appointments, engage with inspiring mentors and mentees, and participate in group mentorship programs. Our platform empowers you to connect, learn, and thrive in a supportive environment that fosters personal and professional growth.
                    </p>

              </div>
        

          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

import React,{useState} from 'react';
import { animateScroll as scroll, Link } from "react-scroll";
import logo from './../components/images/logo.png';

function MobileNav({ isOpen, onClose }) {

    const [navbar, setNavbar] = useState(false);


  return (
    <div className={`mobile-navigation ${isOpen ? 'open' : ''}`}>
      <div className='row-mobile-nav'>
       <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {/* Close button to close the mobile navigation */}
      <div className="close-toggle-button" onClick={onClose}>
        <i className="fa fa-times" />
    </div>
    </div>
      <ul className="mobile-nav-column">
  <li>
    <Link 
    className={navbar ? "anchor" : "anchor"}
    activeClass="active"
    to="home"
    spy={true}
    smooth={true}
    delay={100}
    offset={0}
    duration={500}
    onClick={onClose}>
      Home
    </Link>
  </li>
  <li>
    <Link 
     className={navbar ? "anchor" : "anchor"}
     activeClass="active"
     to="about"
     spy={true}
     smooth={true}
     delay={100}
     offset={0}
     duration={500}
     onClick={onClose}>
      About
    </Link>
  </li>
  <li>
    <Link 
     className={navbar ? "anchor" : "anchor"}
     activeClass="active"
     to="how-it-works"
     spy={true}
     smooth={true}
     delay={100}
     offset={0}
     duration={500}
    onClick={onClose}>
      How it works
    </Link>
  </li> 
 
</ul>


    <a href="https://play.google.com/store/apps/details?id=com.herpath&pli=1" className="signup-button-mobile">
      <button>Download App</button>
    </a>

    </div>
  );
}

export default MobileNav;

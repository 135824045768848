import React from "react";
import NavBar from "./Navbar";
import Footer from "./Footer";
import Welcome from "./Welcome";
import About from "./About";
import HowITWorks from "./HowItWorks";

function HomePage() {

  return (
    <React.Fragment>
          <NavBar />
          <Welcome />
          <About />
          <HowITWorks />
          <Footer />
    </React.Fragment>
  );
}

export default HomePage;
import React, { useEffect, useState } from 'react';
import { animateScroll as scroll, Link } from "react-scroll";
import logo from './../components/images/logo.png';
import MobileNav from './MobileNav';

function NavBar(props) {


  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [navClicked, setNavClicked] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeNav);

  const handleOpenSidebar = () => {
    setSidebarIsOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarIsOpen(false);
  };

  
  return (
    <div className="navbar">
    <div className="logo">
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>

    <div className="nav-links">

    <ul className="nav-row">
  <li>
    <Link 
      className="anchor"
      activeClass="active"
      to="home"
      spy={true}
      smooth={true}
      delay={100}
      offset={0}
      duration={500}
    >
      Home
    </Link>
  </li>
  <li>
    <Link 
       className="anchor"
      activeClass="active"
      to="about"
      spy={true}
      smooth={true}
      delay={100}
      offset={0}
      duration={500}
       >
      About
    </Link>
  </li>
  <li>
    <Link 
      className="anchor"
      activeClass="active"
      to="how-it-works"
      spy={true}
      smooth={true}
      delay={100}
      offset={0}
      >

    How it Works
    </Link>
  </li>
 
</ul>

    </div>

    {/* Mobile menu toggle button on the right side (shown on mobile, hidden on desktop) */}
    <div className="mobile-toggle-button">
      <div
        className={`open-sidebar btn-transparent ${sidebarIsOpen ? 'active' : ''}`}
        onClick={() => setSidebarIsOpen(true)}
      >
        <i className="fa fa-bars" />
      </div>
    </div>

    {sidebarIsOpen && (
        <MobileNav isOpen={sidebarIsOpen} onClose={handleCloseSidebar} />
    )}

   
    <a href="https://play.google.com/store/apps/details?id=com.herpath&pli=1" className="signup-button">
      <button>Download App</button>
    </a>
  </div>
  );
}

export default NavBar;
import React, { Component,useState,useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import features from "./../components/images/features.png";
import user from "./../components/images/user.png";
import users from "./../components/images/users.png";
import calendar from "./../components/images/calendar.png";
import message from "./../components/images/direct-message.png";
import discover from "./../components/images/discover.png";

const contentVariants = {
    initial: {
      translateY: 0, // Set the initial position at the resting point
    },
    animate: {
      translateY: [-20, 0, -20, 0], // Animate between -20 and 0 (up and down)
      transition: {
        yoyo: Infinity, // Continuously repeats the animation
        duration: 6, // Animation duration in seconds
      },
    },
  };
  
function HowITWorks() {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabSwitch = (tabId) => {
      setActiveTab(tabId);
    };
  
    return (
        <section
        className='how-it-works'
        data-aos="fade-down" 
        name="how-it-works"
        id="how-it-works"
        >
             <div className="switch-tab-container-how-it-works mt-3">
                <div className='tabs mb-3'>
                <span className="active-tab">
                How it works
              </span>
              <span className="tab2">
               Our Features
              </span>
                </div>
            </div>
            <h3>HerPath Mentorship Platform Highlights</h3>

           
            <div class="row mt-5 row-reverse">
                <div className='row-left'>
                       <motion.div
                        className=''
                        animate='animate'
                        variants={contentVariants}
                        >
                        <img src={features} className='about-image' />
                        </motion.div>     
                </div>
                <div className='row-right mobile-row'>
                       
                       <div className='item-wrapper'>
                          <img src={discover}  />
                          <p>Disover and connect</p>
                       </div>
                       <div className='item-wrapper'>
                          <img src={calendar}  />
                          <p>Schedule Appointment</p>
                       </div>
                       <div className='item-wrapper'>
                          <img src={user}  />
                          <p>Detailed Profile</p>
                       </div>
                       <div className='item-wrapper'>
                          <img src={users}  />
                          <p>Group Mentorship</p>
                       </div>
                       <div className='item-wrapper'>
                          <img src={message}  />
                          <p>Direct Messaging</p>
                       </div>
                </div>
            </div>
           
        </section>
  );
}
export default HowITWorks;
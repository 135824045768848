import React, { Component,useEffect } from "react";
import "./components/css/styles.css";
import Aos from "aos";
import "aos/dist/aos.css";
import HomePage from "./components/Home";

function App() {  
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 200 });
  }, []);

  return (
    <div className="container">
    <HomePage />
  </div>
  );
}

export default App;

import React from 'react';
import { animateScroll as scroll, Link } from "react-scroll";
import logo from './images/logo2.png';

export default function Footer(){

    const date= new Date();

    const handleSubmit = (e) => {
      e.preventDefault();

    }
    return(
        <div className="footer">

         <div className="footer-container">
            <div className='footer-left'>
                <img src={logo} />
                <h3>Get new information about HerPath</h3>
               <div className='newsletter'>
               <form onSubmit={handleSubmit}>
                <div className="row">
                <input
                    type="email"
                    id="lastName"
                    name="email"
                />    
            <button type="submit" className="btn-primary news-letter-button">
              <i className="fas fa-paper-plane"></i>
            </button>
            </div>
            </form> 
               </div>
             
             </div>  
             <div className='footer-right'>
                <ul className="footer-links">
                <li>
            <Link 
            className="footer-link footer-bold"
            activeClass="active"
            to="how-it-works"
            spy={true}
            smooth={true}
            delay={100}
            offset={0}
            >

            How it Works
            </Link>
        </li>
        <li>
            <Link 
            className="footer-link footer-bold"
            activeClass="active"
            to="how-it-works"
            spy={true}
            smooth={true}
            delay={100}
            offset={0}
            >

            Features
            </Link>
        </li>
        <li>
        <a href="https://play.google.com/store/apps/details?id=com.herpath&pli=1" className="footer-link">
        Download App
        </a>          
  </li>
  <li>
    <Link 
      className="footer-link"
      activeClass="active"
      to="about"
      spy={true}
      smooth={true}
      delay={100}
      offset={0}
      duration={500}
    >
      About
    </Link>
  </li>
  <li>
    <Link 
       className="footer-link"
      activeClass="active"
      to="how-it-works"
      spy={true}
      smooth={true}
      delay={100}
      offset={0}
      duration={500}
       >
      Features
    </Link>
  </li>
 
                </ul>
            </div>
            
         </div>  

         <div className='footer-bottom'>
           <div className='footer-bottom-left'>
           <p>&copy; {date.getFullYear()} HerPath All Rights Reserved</p>
           <span>|</span>
           <a href="">Privacy Policy</a>
           <span>|</span>
           <a href="">Terms & Conditions</a>
           </div>
              <div className='row footer-social'>
                    <a href=""><i className='fa fa-facebook s-link' /></a>
                    <a href=""><i className='fa fa-instagram s-link' /></a>
                    <a href=""><i className='fa fa-x s-link' /></a>
                    <a href=""><i className='fa fa-linkedin s-link' /></a>
                </div>
            </div>  
      
  </div>

        );
    }